import React from "react";
import DoctorCard from "./DoctorCard";
import profile1 from "../Assets/profile-1.png";
import profile2 from "../Assets/profile-2.png";
import profile3 from "../Assets/profile-3.png";
import profile4 from "../Assets/profile-4.png";
import "../Styles/Doctors.css";

function Achievers() {
  return (
    <div className="doctor-section" id="Acheivers">
      <div className="dt-title-content">
        <h3 className="dt-title">
          <span>Meet Our Brand Ambassador </span>
        </h3>
        <p className="dt-description">
    
"Driven by ambition, fueled by perseverance, 
achievers turn dreams into reality and obstacles into opportunities."
        </p>
      </div>

      <div className="dt-cards-content">
        <DoctorCard
          img={profile1}
          name="Ansh Singh"
          title="Class 8th"
          // stars="4.9"
           reviews="Sainik School Entrance Exam Qaulifed ❤️ "
         
        />
       
      </div>
    </div>
  );
}

export default Achievers;
