import React, { useEffect, useState } from "react";
import Doctor from "../Assets/doctor-picture.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate  } from "react-router-dom";
import "../Styles/Hero.css";

function Hero() {
  const navigate = useNavigate();
  const [goUp, setGoUp] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBookAppointmentClick = () => {
    window.location.href = "https://forms.gle/ojCKaYM8smtGL7KE7";
  };

  useEffect(() => {
    const onPageScroll = () => {
      if (window.scrollY > 600) {
        setGoUp(true);
      } else {
        setGoUp(false);
      }
    };
    window.addEventListener("scroll", onPageScroll);

    return () => {
      window.removeEventListener("scroll", onPageScroll);
    };
  }, []);

  return (
    <div className="section-container">
      <div className="hero-section">
        <div className="text-section">
          <p className="text-headline">❤️ Education  first</p>
          <h2 className="text-title">
          Unlock Your Potential, Learn with Us
          </h2>
          <p className="text-descritpion">
          The road to learning can feel long and winding, but remember, every step is a victory.
           Challenges are inevitable, but they are also opportunities to grow and refine your skills. 
           Embrace the journey, celebrate the small wins, and focus on the incredible potential that unfolds with each new piece of knowledge you gain. You are capable of achieving great things. Let education be the fuel that propels you forward,
           and never lose sight of the fire of curiosity that ignites your desire to learn.
          </p>
          <button
            className="text-appointment-btn"
            type="button"
            onClick={handleBookAppointmentClick}
          >
            <FontAwesomeIcon icon={faCalendarCheck} /> Book Demo
          </button>
          <div className="text-stats">
            <div className="text-stats-container">
              <p>200+</p>
              <p>Students</p>
            </div>

            <div className="text-stats-container">
              <p>3+</p>
              <p>Experts</p>
            </div>

            <div className="text-stats-container">
              <p>10+</p>
              <p>Years of Experience</p>
            </div>
            
            <div className="text-stats-container">
              <p>8791751161 </p>
              <p>Call Now</p>
            </div>
          </div>
        </div>

        <div className="hero-image-section">
          <img className="hero-image1" src={Doctor} alt="Doctor" />
        </div>
      </div>

      <div
        onClick={scrollToTop}
        className={`scroll-up ${goUp ? "show-scroll" : ""}`}
      >
        <FontAwesomeIcon icon={faAngleUp} />
      </div>
    </div>
  );
}

export default Hero;
