import React from "react";
import Doctor from "../Assets/doctor-group.png";
import SolutionStep from "./SolutionStep";
import "../Styles/About.css";

function About() {
  return (
    <div className="about-section" id="about">
      <div className="about-image-content">
        <img src={Doctor} alt="Doctor Group" className="about-image1" />
      </div>

      <div className="about-text-content">
        <h3 className="about-title">
          <span>About Us</span>
        </h3>
        <p className="about-description">
        Rajan Singh(Maths Faculty), a mechanical engineer, is passionate about sharing his knowledge through teaching.<br /><br />
         Excels in simplifying complex concepts, making learning engaging and accessible.<br /><br />
        My teaching style involves hands-on demonstrations and personalized guidance.<br /><br />
        I always  aims to ignite a passion for learning in his students, equipping them with practical skills.<br /><br />
        As a mentor, I takes pride in nurturing the next generation of innovative thinkers and problem solvers. <br /><br />
   

        </p>

        <h4 className="about-text-title">Other Faculty</h4>

        <SolutionStep
          title="Sweta Singh"
          description="B.sc Mathsmatics and Physics "
        />

      </div>
    </div>
  );
}

export default About;
